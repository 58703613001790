import CmsBlock from '@components/CmsBlock'
import { StyledLinks } from './styled'

const Links = () => {
  return (
    <StyledLinks>
      <CmsBlock identifiers="footer_links_ginnienamel" portal />
    </StyledLinks>
  )
}

export default Links
