import { useCmsNavigation } from '@hooks/CmsNavigation'

import MobileNav from './MobileNav'
import PcNav from './PcNav'

const CmsNavigation = ({
  onMobileClickOutside
}: {
  onMobileClickOutside: () => any
}) => {
  const { categories, isMobile } = useCmsNavigation()

  return isMobile ? (
    <MobileNav
      onMobileClickOutside={onMobileClickOutside}
      categories={categories}
    />
  ) : (
    <PcNav categories={categories} />
  )
}

export default CmsNavigation
