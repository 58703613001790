import parse, { HTMLReactParserOptions, domToReact } from 'html-react-parser'
import { isEmpty } from 'lodash'
import { FC, memo } from 'react'

import { Link, Portal } from '@headless/ui'
import { useCmsBlock } from '@hooks/CmsBlock'

import Subscribe from '@components/Subscribe'

interface CmsBlockProps {
  identifiers: string
  portal?: boolean
}

const CmsBlock: FC<CmsBlockProps> = ({
  identifiers,
  portal = false,
  ...props
}) => {
  const { block, visible } = useCmsBlock(identifiers)

  const options: HTMLReactParserOptions = {
    replace: (node: any) => {
      if (['directive', 'head', 'body'].includes(node.name)) {
        return <div>{domToReact(node.children, options)}</div>
      }

      if (node.name === 'a' && identifiers === 'footer_links') {
        const { href } = node.attribs
        return (
          <Link
            href={href}
            className={node.attribs?.calss ?? ''}
            title={node.attribs?.title ?? ''}
          >
            {domToReact(node.children, options)}
          </Link>
        )
      }

      if (node.type === 'script') {
        const script = document.createElement('script')

        if (node.attribs && node.attribs.src) {
          script.src = node.attribs.src
          script.async = true
        } else {
          script.innerHTML = node.children[0].data
        }

        document.head.appendChild(script)
      }
    }
  }

  return isEmpty(block) ? null : (
    <div className="cms-block" {...props}>
      {parse(block.content, options)}
      {visible && portal && (
        <>
          {document.querySelector('.newsletter') && (
            <Portal container={document.querySelector('.newsletter')}>
              <Subscribe />
            </Portal>
          )}

          {document.querySelector('.social_links') && (
            <Portal container={document.querySelector('.social_links')}>
              <li>
                <a href="https://www.facebook.com/profile.php?id=61551945098606" />
              </li>
              <li>
                <a href="https://www.instagram.com/ginnijewelry/" />
              </li>
              <li>
                <a href="https://www.pinterest.com/ginnijewelry/" />
              </li>
              {/* <li>
                <a href="https://www.tiktok.com/@sweetvjewelry/" />
              </li> */}
            </Portal>
          )}
        </>
      )}
    </div>
  )
}

export default memo(CmsBlock)
